var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contentBox"},[_c('section',[_c('el-tabs',{staticStyle:{"height":"500px"},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"邮箱认证","name":"first"}},[_c('div',{staticClass:"tabBox"},[_c('div',{staticClass:"tabTitle"},[_vm._v(" 邮箱认证 ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.email),expression:"!email"}],staticClass:"formBox text-center"},[_c('el-form',{ref:"realEmailInfo",attrs:{"model":_vm.realEmailInfo,"label-width":"55px"}},[_c('el-form-item',{attrs:{"label":"邮箱","prop":"email","rules":[
                  {
                    required: true,
                    message: '请输入邮箱地址',
                    trigger: 'blur',
                  },
                  {
                    type: 'email',
                    message: '请输入正确的邮箱地址',
                    trigger: ['blur'],
                  } ]}},[_c('el-input',{model:{value:(_vm.realEmailInfo.email),callback:function ($$v) {_vm.$set(_vm.realEmailInfo, "email", $$v)},expression:"realEmailInfo.email"}})],1),_c('p',{staticStyle:{"margin-left":"55px","font-size":"12px","color":"#f00","text-align":"left"}},[_vm._v(" 系统会自动发送一封认证邮箱，提交后请登录您的邮箱确认。 ")]),_c('div',{staticClass:"text-center",staticStyle:{"padding":"10px","margin-top":"20px"}},[_c('el-button',{directives:[{name:"prevent-repeat-click",rawName:"v-prevent-repeat-click"}],staticClass:"radius-25",staticStyle:{"width":"280px","box-shadow":"0px 2px 10px 0px #8BB7FF"},attrs:{"type":"primary"},on:{"click":_vm.realEmail}},[_vm._v(" 提交 ")])],1)],1)],1),(_vm.email)?_c('div',{staticClass:"emailAuthBox"},[_vm._v(" 您已认证邮箱："),_c('span',{staticClass:"font-blue"},[_vm._v(_vm._s(_vm.email))])]):_vm._e()])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }