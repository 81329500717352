<!--
 * @Description: 企业-认证中心
 * @Author: huacong
 * @Date: 2021-04-06 09:43:32
 * @LastEditTime: 2021-09-02 15:28:43
 * @LastEditors: huacong
-->
<template>
  <div class="contentBox">
    <section>
      <el-tabs v-model="activeName" style="height:500px">
        <el-tab-pane label="邮箱认证" name="first">
          <div class="tabBox">
            <div class="tabTitle">
              邮箱认证
            </div>
            <div class="formBox text-center" v-show="!email">
              <el-form
                :model="realEmailInfo"
                ref="realEmailInfo"
                label-width="55px"
              >
                <el-form-item
                  label="邮箱"
                  prop="email"
                  :rules="[
                    {
                      required: true,
                      message: '请输入邮箱地址',
                      trigger: 'blur',
                    },
                    {
                      type: 'email',
                      message: '请输入正确的邮箱地址',
                      trigger: ['blur'],
                    },
                  ]"
                >
                  <el-input v-model="realEmailInfo.email"></el-input>
                </el-form-item>
                <p
                  style="margin-left:55px;font-size: 12px;color: #f00;text-align:left"
                >
                  系统会自动发送一封认证邮箱，提交后请登录您的邮箱确认。
                </p>
                <div class="text-center" style="padding:10px;margin-top:20px">
                  <el-button
                    class="radius-25"
                    style="width: 280px;box-shadow: 0px 2px 10px 0px #8BB7FF;"
                    type="primary"
                    v-prevent-repeat-click
                    @click="realEmail"
                  >
                    提交
                  </el-button>
                </div>
              </el-form>
            </div>
            <div v-if="email" class="emailAuthBox">
              您已认证邮箱：<span class="font-blue">{{ email }}</span>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      activeName: "first",
      realEmailInfo: {},
      comInfo: {},
      imageUrl: "",
      licenseUrl: "",
      uploadUrl: "",
      licenseData: {},
      email: "",
    };
  },
  computed: {
    ...mapState(["uploadSrc", "userInfo", "comRealeInfo", "Authorization"]),
    userInfo() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
    headers() {
      return {
        Accept: "application/json",
        token: this.Authorization,
      };
    },
  },
  methods: {
    getUrl() {
      this.licenseData.fi_objid = this.userInfo.uid;
      this.licenseData.fi_objcat = "com";
      this.licenseData.act = "upload";
    },
    realEmail() {
      this.$refs.realEmailInfo.validate((valid) => {
        if (valid) {
          this.$http
            .post("company/user/emailauth", this.realEmailInfo)
            .then((res) => {
              if (res.data.resultCode == 1) {
                this.$message.success("邮件已发送！");
              }
            });
        } else {
          return false;
        }
      });
    },
    // 营业执照
    handleAvatarSuccess2(res, file) {
      if (res.resultCode === 0) {
        this.$message.warning("图片上传失败！");
        return;
      }
      this.licenseUrl = URL.createObjectURL(file.raw);
      console.log(res);
      let imgId = res.resultData.file_id;

      this.$http
        .get(
          "index/file_upload/upfile?fi_objid=5&fi_objcat=com&act=download&fi_id=" +
            imgId
        )
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.comInfo.url = res.data.resultData.url;
          }
        });
    },
    beforeAvatarUpload2(file) {
      const isJP = file.type === "image/jpeg" || file.type === "image/png";
      const isLt3M = file.size / 1024 / 1024 < 3;
      const isLt1M = file.size / 1024 / 1024 > 1;

      if (!isJP) {
        this.$message.error("上传图片只能是 JPG 格式或 PNG 格式!");
      }
      if (!isLt1M || !isLt3M) {
        this.$message.error("上传图片最大不能超过 3MB，最小不低于1MB!");
      }
      return isJP && isLt1M;
    },
    postImg() {
      this.$http.post("company/index/associated", this.comInfo).then((res) => {
        if (res.data.resultCode === 1) {
          console.log(res);
        }
      });
    },
    getReale() {
      this.$http
        .post("company/index/complete", { com_id: this.comRealeInfo.id })
        .then((res) => {
          if (res.data.resultCode === 1) {
            if (res.data.resultData.flag) {
              this.getEmail(1);
            } else {
              this.getEmail(0);
            }
          }
        });
    },
    getEmail(reale) {
      let info = {
        com_auth: reale,
        com_id: this.comRealeInfo.id,
      };
      this.$http.post("company/user/headhunt", info).then((res) => {
        if (res.data.resultCode == 1) {
          if (res.data.resultData[0].eamail) {
            this.email = res.data.resultData[0].eamail;
          }
        }
      });
    },
  },
  mounted() {
    this.getUrl();
    this.getReale();
  },
};
</script>
<style scoped>
.formBox {
  width: 500px;
  margin: 0 auto;
}
.tabTitle {
  font-size: 28px;
  color: #2573f1;
  font-weight: bold;
  text-align: center;
  margin: 30px 0;
}
.tabTitle::before {
  content: "";
  display: inline-block;
  width: 73px;
  height: 3px;
  background-image: url("../../../assets/img/lineLeft.png");
  margin-right: 24px;
  position: relative;
  top: -7px;
}
.tabTitle::after {
  content: "";
  display: inline-block;
  width: 73px;
  height: 3px;
  background-image: url("../../../assets/img/lineRight.png");
  margin-left: 24px;
  position: relative;
  top: -7px;
}
.licenseMsg {
  width: 445px;
  position: absolute;
  top: 0;
  left: 240px;
  text-align: left;
}
.licenseMsg p:first-child,
.avatarMsg p:first-child {
  color: #ff0000;
}
.licenseMsg p {
  line-height: 30px;
}
.emailAuthBox {
  width: 400px;
  font-size: 20px;
  font-weight: bold;
  padding: 30px;
  text-align: center;
  margin: 0 auto;
}
</style>
